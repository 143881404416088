import { AdminApp } from "./admin/AdminApp.js";
import { ButtonApp } from "./front/ButtonApp.js";
import { PmSmsChat } from "./API/PmSmsChat.js";
import { PmShortUrl } from "./API/PmShortUrl.js";
import { PmApiDtm } from "./API/PmApiDtm.js";
import { PmCustomerAPI } from "./API/PmCustomerAPI.js";
import { PmDealersite } from "./API/PmDealersite.js";

export default class {
    constructor(config) {
        let pmchat = this;
        //CONFIG
        this.config = this.defaultConfig();
        Object.assign(this.config, config);
    
        // API
        this.pmSmsChat = new PmSmsChat(pmchat);
        this.pmApiDtm = new PmApiDtm(pmchat);
        this.pmShortUrl = new PmShortUrl(pmchat);
        this.pmCustomerApi = new PmCustomerAPI(pmchat);
        this.pmDealersite = new PmDealersite({
            env: this.config.env,
        });

        this.dealerSettings = null;
        this.init();
    }

    init() {
        var pmchat = this;
        this.addHtml();

        // GET DEALER SETTINGS
        this.pmSmsChat.getDealerSettings(this.config.site_token,
            response => {
                pmchat.dealerSettings = JSON.parse(response);
            },
            error => {

            })
    }

    getSessionId() {//this should be the only way to read sessionId
        return this.config.sessionId;
    }  

    createAdminApp(adminAppConfig) {
        this.adminApp = new AdminApp(this, adminAppConfig);
    }

    createButtonApp(buttonAppConfig) {
        var maxAttempt = 10;
        var $this = this;
        var createBtnApp = function() {            
            if($this.dealerSettings){//init only when dealer settings are ready                
                $this.buttonApp = new ButtonApp($this, buttonAppConfig);        
            }else{
                if(maxAttempt-->0){
                    setTimeout(() => {
                        createBtnApp();
                    }, 500);
                }else{
                    console.error('PM Button App: no dealer settings')
                }
            }
        }
        createBtnApp();
    }

    addHtml() {      
        //PMDRS Style
        let cssUrl = "dist/css/" + this.config.cssFile + "?ver=" + Math.floor(Math.random() * 100);        
        if(this.isProduction()){
            cssUrl = 'https://smschat.dev.pixelmotiondemo.com:3000/dist/css/' + this.config.cssFile;        
        }
        
        document
            .querySelector("head")
            .insertAdjacentHTML("beforeend", `<link rel="stylesheet" type="text/css" href="` + cssUrl + `" />`);
    }

    isProduction(){
        return this.config.env == 'production';
    }

    isBMW(){
        return this.config.oem.toLowerCase() == 'bmwmotorrad';
    }

    defaultConfig() {
        return {            
            site_token: "dev",
            oem: "bmwmotorrad",
            env: 'dev', //dev or production
            cssFile: "pm-chat-app.css",
            sessionId: 1
        };
    }
}
