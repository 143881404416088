import { DOMHelper } from "../Helpers/DOMHelper.js";
import { PmDatalayer } from "./PmDatalayer.js";

export class PmBtnAppDatalayer extends PmDatalayer {

    constructor(app) {
        super(app.pmchat.pmCustomerApi);
        this.app = app;               
        this.setSessionId(app.pmchat.getSessionId());
    }    

    init() {
        this.addHandlers();        
    }

    addHandlers() {
        var dataLayer = this;

        var generalClickEvents = [
            { selector: '.pmcb-radio-option', section: PmBtnAppDatalayer.QUICK_CHAT_SECTION, element: 'Department Option' },        
        ];

        generalClickEvents.forEach((item)=> {            
            DOMHelper.addHandler(
                this.app.getSelector(item.selector), 
                "click", 
                (event) => {                    
                    console.log("Pm dataLayer selector: " + item.selector);
                    dataLayer.generalClickEvent(item.section, item.element, event.target);
                }
            );
        });

        //CHAT CLICK EVENT + IMPRESSION
        DOMHelper.addHandler(
            this.app.getSelector('.pmcb-floating-btn'),
            "click", 
            (event) => {   
                dataLayer.chatImpressionEvent(PmBtnAppDatalayer.QUICK_CHAT_SECTION);                
                dataLayer.chatClickEvent(PmBtnAppDatalayer.QUICK_CHAT_SECTION);                
            }
        );

        //CHAT CLOSED EVENT
        DOMHelper.addHandler(
            this.app.getSelector('.pmcb-x-btn'),
            "click", 
            (event) => {   
                dataLayer.chatClosedEvent(PmBtnAppDatalayer.QUICK_CHAT_SECTION);                
            }
        );
    }

}

PmBtnAppDatalayer.QUICK_CHAT_SECTION = "quick chat";
