import { DOMHelper } from "../Helpers/DOMHelper.js";
import { ShiftBmwModule } from "./ShiftBmwModule.js";

/**
 * @class top level to handle datalayers by brand, bmw, polaris, etc
 */
export class ShiftDatalayer {

    constructor(app) {        
        this.app = app;
        this.initiatedForms = {};// to remember and not double fire it
        this.modules = {};
        this.sessionId = this.app.pmchat.getSessionId();
    }

    getSessionId(){
        return this.sessionId;
    }

    init() {        
        //register modules
        if(this.app.pmchat.isBMW()){
            let bmwModule = new ShiftBmwModule(this); 
            if(bmwModule.init()){
                this.modules['bmw'] = bmwModule; 
            }
        }
        this.addHandlers();        
    }

    fireEvent(eventName, params){
        const functionName = eventName + "Event";
        for (let moduleKey in this.modules) {
            const module = this.modules[moduleKey];
            if(typeof module[functionName] === 'function'){
                module[functionName](params);
            }
        }
    }

    addHandlers() {
        var dataLayer = this;
        //FORM INTERACTION AND INIT EVENTS
        var inputEvents = [
            { selector: 'form.pmcb-msg-form input', formType: 'Other'},
        ];

        inputEvents.forEach(item => {
            DOMHelper.addHandler(
                this.app.getSelector(item.selector),
                "focusin", 
                (event) => {   
                    let args = {
                        formType: item.formType,
                        formFieldName: event.target.name
                    }
                    dataLayer.fireEvent('formFieldInteraction', args);
                    if(dataLayer.initiatedForms[item.selector]){
                        return;//skip double
                    }
                    dataLayer.fireEvent('formInitiation', item);
                    dataLayer.initiatedForms[item.selector] = true;
                }
            );
        });

        //CHAT CLICK EVENT + IMPRESSION
        DOMHelper.addHandler(
            this.app.getSelector('.pmcb-floating-btn'),
            "click", 
            (event) => {   
                let args = {}
                dataLayer.fireEvent('chatImpression', args);                
                dataLayer.fireEvent('chatClick', args);
            }
        );

        //CHAT CLOSED
        DOMHelper.addHandler(
            this.app.getSelector('.pmcb-x-btn'),
            "click", 
            (event) => {   
                let args = {}
                dataLayer.fireEvent('chatClosed', args);                
            }
        );
    }

    getVehicleDetails() {
        return null; 
        // const v = this.app.config.vehicle;
        // let vehicleDetails = {
        //     status: v.condition, //condition
        //     year: v.year,
        //     make: v.make,
        //     model: v.model + " " + v.trim,
        //     exteriorColor: "",//we don't have it
        //     vin: v.vin,
        //     msrp: this.app.getVehicleRetailPrice(),
        //     displayedPrice: v.finalPrice
        // };
        // return vehicleDetails;
    }
}