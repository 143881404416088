import { DOMHelper } from "../Helpers/DOMHelper.js";
import { RequestHelper } from '../Helpers/RequestHelper.js';
import { BrowserHelper } from '../Helpers/BrowserHelper.js';
import { PmBtnAppDatalayer } from '../Datalayer/PmBtnAppDatalayer.js';
import { ShiftDatalayer } from '../Datalayer/ShiftDatalayer.js';

export class ButtonApp {
	
	constructor(pmchat, config){		
		let app = this;
		app.pmchat = pmchat;
		//CONFIG		
		this.config = this.defaultConfig();		
		Object.assign(this.config, config);
		
		//ONLY TO ADMINS VALIDATION
		if(this.pmchat.dealerSettings.drs_settings.quickChatOnlyAdmins){
			console.info("ButtonApp enabled only to admins");
			if(!this.config.adminLoggedIn){
				return;
			}
		}

		//ATTS
		this.pmcbCaptchaId = null;
		this.wrapperClass = 'pm-chat-button-app';
		this.appId = 'pmcb_' + Date.now() + '_' + Math.random().toString(36).substr(2, 9);
        
		this.state = {
			currentPage: 0,
			selectedDepartment: null
		}		

		//init
		this.init();

		this.pmBtnAppDatalayer = new PmBtnAppDatalayer(this);
		this.pmBtnAppDatalayer.init();

		this.shiftDatalayer = new ShiftDatalayer(this);
		this.shiftDatalayer.init();
	}


	defaultConfig(){
		return {
			recaptchaSiteKey: '6Lc6cyQkAAAAAH4x37pQL69AsErQf3WY3JyFw6Qi',
			adminLoggedIn: false,
		};
	}

	init(){
		this.addHtml();
        this.addHandlers();
		this.setRecaptchaKeys();
		this.setInitialValues();		
		this.setConsentFields();
	}	

	setInitialValues(){
		if(this.pmchat.dealerSettings){
			let apiFromNumber = this.pmchat.dealerSettings.api_from_number;
			DOMHelper.setInnerHTML(this.getSelector('.api-from-number-value'), apiFromNumber);
		}
		let dealerName = location.hostname;
		if('pm_datalayer_props' in window){
			dealerName = pm_datalayer_props.dealerName || dealerName;
		}
		DOMHelper.setInnerHTML(this.getSelector('.dealername-value'), dealerName);
		this.setLeadIdValue();
	}

	addHtml(){
		var classes = [
			this.wrapperClass
		].join(" ");

		document.querySelector("body")
			.insertAdjacentHTML('beforeend',`<div class="` + classes +`" id="`+ this.appId +`"><div class="pmcb-app">    
    <div class="pmcb-panel">
        <form class="pmcb-msg-form" action="">
            <input type="hidden" name="pmTwilioSend" value="1">
            <input type="hidden" name="ajax" value="1">
            <input type="hidden" name="source" value="pm-button-app">
            <input type="hidden" name="leadId" value="">
            <input type="hidden" name="form_title" value="Quick Chat">
            <input type="hidden" name="preferredmethodofcontactField" value="Text Messages">

            <!-- page 0 -->
            <div class="pmcb-panel-page active" data-page="0">
                <div class="pmcb-panel-title"><i class="pmc-fa pmc-fa-user"></i> &nbsp; Choose a Department <span class="pmcb-x-btn">x</span></div>
                <div class="pmcb-panel-body">
                    <div class="pmcb-body-section">
                        <div class="pmcb-radio-option">
                            <input type="radio" name="department" value="sales">
                            Sales
                        </div>
                        <div class="pmcb-radio-option">
                            <input type="radio" name="department" value="service">
                            Service
                        </div>
                        <div class="pmcb-radio-option">
                            <input type="radio" name="department" value="parts">
                            Parts
                        </div>
                    </div>
                </div>
            </div>
            <!-- page 1 -->
            <div class="pmcb-panel-page" data-page="1">
                <div class="pmcb-panel-title">
                    <i class="pmc-fa pmc-fa-arrow-left pmcb-panel-back-btn"></i> 
                    &nbsp;Send a text to <span class="department-value">department</span> 
                    <span class="pmcb-x-btn">x</span>
                </div>
                <div class="pmcb-panel-body">
                    <p style="margin-top: 0;">Add your details and a short message, we'll respond with a text</p>
                    <div class="pmcb-body-section">
                        <div class="pmc-input">
    <label>First and Last Name</label>
    <input type="text" name="pmFirstField" required>
    <span class="input-error"></span> 
</div>

                        <div class="pmc-input">
                            <label>Mobile Number</label>
                            <input type="text" name="pmPhoneField"                             
                            maxlength="14" 
                            pattern="\\(\\d{3}\\) \\d{3}-\\d{4}"
                            required>
                            <span class="input-error"></span> 
                        </div>

                        <div class="pmc-input">
    <label>Message</label>
    <input type="text" name="pmMessageField" required>
    <span class="input-error"></span> 
</div>

                        <label class="pmcb-consent-label tcpa-label">                            
                            <input type="checkbox" name="tcpa_consentField" aria-required="true" aria-invalid="false" required>
                            <span></span>
                        </label>
                        <input type="hidden" name="tcpa_textField" value="I agree TCPA consent">
                        <input type="hidden" name="tcpa_descriptionField">
                    </div>
                    <div class="g-recaptcha" data-sitekey="" id="pmcb_captcha"></div>
                    <p class="pmcb-msg-form-error"></p>
                    <button class="pmcb-button" type="submit">Send</button>
                    <p class="pmcb-disclaimer">
                        By clicking “Send,” you give permission for <span class="dealername-value"></span> to send text messages and marketing materials to the mobile number you've provided, which may include automated technology. Your consent is not required for purchase. Standard message and data rates may apply. To opt out at any time, text STOP.
                    </p>
                </div>
            </div>
            <!-- page 2 -->
            <div class="pmcb-panel-page" data-page="2">
                <div class="pmcb-panel-title"><i class="pmc-fa pmc-fa-check"></i> &nbsp; Text Message Sent <span class="pmcb-x-btn">x</span></div>
                <div class="pmcb-panel-body" style="background-color: white;">
                    <p class="chat-message msg-value"></p>
                    <p class="chat-message dealer-message">
                        We've received you message!
                        You'll receive a text message back from the following number: <span class="api-from-number-value"></span>
                    </p>
                    <p class="chat-message dealer-message">Thank you!</p>
                </div>
            </div>
        </form>
    </div>
    <div>
        <button class="pmcb-floating-btn show"> <i class="pmc-fa pmc-fa-envelope"></i> Text Us !</button>
        <button class="pmcb-floating-btn no-mobile"> <i class="pmc-fa pmc-fa-x"></i> Close</button>
    </div>    
</div></div>`);		        

		//SPOT FOR MODALS HTML
        // document
        //     .querySelector("body")
        //     .insertAdjacentHTML(
        //         "beforeend",
        //         "<div class='" + classes + " pm-chat-modals'></div>"
        //     );
	}

	//TCPA and CCPA texts and inputs
	setConsentFields() {
        var $this=this;
        var onComplete = function(consents){
            if(!consents){
                console.error('Error getting CCPA and TCPA descriptions');
                //DEFAULTS
                consents = {
                    ccpa: `*By checking here, I direct this site to send me text messages to market or advertise products, goods, or services. These text messages may be transmitted through autodialed calls or robotext. By checking the checkbox and
        clicking submit, I confirm that I am the current owner/subscriber of the mobile number provided or that the current owner/subscribed of this mobile phone number authorized me to provide this number to the dealer. I
        understand that my consent is not required as a condition of purchase and that I can revoke my consent at any time. My carrier wireless and text message fees may apply. I will contact the dealer directly to provide
        reasonable notice if I no longer wish to receive automated calls or texts.`,
                    tcpa: `*By submitting this form I understand that this site may contact me with offers or information about their products and service.`
                }
            }          
            // let ccpa = document.querySelector($this.getSelector("#ccpa-description span"));
            // ccpa.textContent = $this.consents.ccpa;
            let tcpa = document.querySelector($this.getSelector(".tcpa-label span"));
            tcpa.textContent = consents.tcpa;            
            DOMHelper.setValue($this.getSelector("input[name=tcpa_descriptionField]"), consents.tcpa);
        }
        return this.pmchat.pmDealersite.getCCPATCPADescriptions(
            (response) => {
                let consents = JSON.parse(response);                
                // default value
                onComplete(consents);
            },
            (error) => {
                console.log(error);
                onComplete(null);
            }
        );

        // document.querySelector(this.getSelector(".container-tcpa-ccpa")).style.display = "none";
    }

	setRecaptchaKeys(){		
	    if(typeof grecaptcha === 'undefined') {
	    	console.error('Google recaptcha missing');
	    	return;
		}

		var $this = this;
		let selector = $this.getSelector(".g-recaptcha");
		DOMHelper.querySelectorAction(selector, (item) => {			
			$this.pmcbCaptchaId = grecaptcha.render(item, {
	          'sitekey' : $this.config.recaptchaSiteKey	          
	        });
		});
	}

	setPageActive(page){
		DOMHelper.removeClass(this.getSelector('.pmcb-panel-page'), 'active');			
		DOMHelper.addClass(this.getSelector(".pmcb-panel-page[data-page='"+ page +"']"), 'active');
		this.state.currentPage = page;
	}

    addHandlers() {
        var $this = this;
		
		DOMHelper.addHandler($this.getSelector("input[name=pmPhoneField]"), "input", (event) => {
			let input = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
			if (input.length > 3 && input.length <= 6) {
				input = `(${input.slice(0, 3)}) ${input.slice(3)}`;
			} else if (input.length > 6) {
				input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
			}
			event.target.value = input;
		});

        DOMHelper.addHandler($this.getSelector(".pmcb-floating-btn")
		+ ", " + $this.getSelector(".pmcb-x-btn"), "click", function(){
            DOMHelper.toggleClass($this.getSelector(".pmcb-floating-btn"), 'show');
            DOMHelper.toggleClass($this.getSelector(".pmcb-panel"), 'show');
        });

		DOMHelper.addHandler($this.getSelector(".pmcb-radio-option"), "click", function(e){
			let $currentPage = document.querySelector($this.getSelector(".pmcb-panel-page.active"));
			let nextPage = Number($currentPage.dataset.page) + 1;
            $this.setPageActive(nextPage);
			let radioInput = e.target.querySelector('input');
			radioInput.checked=true;
			$this.state.selectedDepartment = radioInput.value;
			DOMHelper.setInnerHTML($this.getSelector('.department-value'), $this.state.selectedDepartment);			
        });
		
		DOMHelper.addHandler($this.getSelector(".pmcb-panel-back-btn"), "click", function(){
            $this.setPageActive($this.state.currentPage-1);
        }); 

		DOMHelper.addHandler($this.getSelector('.pmcb-msg-form'),'submit',(e) => {
			e.preventDefault();
			//submit
			// FRONT CAPTCHA CHECK
			var captchaResponse = grecaptcha.getResponse($this.pmcbCaptchaId);//id comes from render 
			if (captchaResponse.length === 0) {
				$this.showFormErrorMsg("Please complete the captcha");
				return false;
			}			
			var formData = new FormData(e.target);        
			// print data
			for (let [key, value] of formData.entries()) {
				console.log(key + ': ' + value);
			}
			var submitBtn = e.target.querySelector('button[type=submit]')
			submitBtn.disabled = true;
        	formData = this.addExtraFormData(formData);

			//ajax
			this.pmchat.pmDealersite.sendTwilioMsg(
				formData,
				(response) => {
					//SEND LEAD to api.dtm
	                $this.pmchat.pmApiDtm.postChatLead(
	                    formData,
	                    (apiDtmResponse) => {
	                        console.log('Chat Lead Saved on api.dtm');
	                    },
	                    (apiDtmError) => {
	                        console.log(apiDtmError);
	                    }
	                );

					let msg = document.querySelector($this.getSelector("input[name='pmMessageField']")).value;
					DOMHelper.setInnerHTML($this.getSelector('.msg-value'), msg);					
					$this.setPageActive(2);		
                	const leadId = formData.get("leadId");

					//DATALAYERS: CHAT LEAD
	                $this.shiftDatalayer.fireEvent('chatLead', {chatLeadId: leadId})
	                $this.pmBtnAppDatalayer.chatLeadEvent(PmBtnAppDatalayer.QUICK_CHAT_SECTION, leadId, formData)
	                
	                //DATALAYERS: CHAT MESSAGE
	                const chatMsg = formData.get("pmMessageField");
	                $this.shiftDatalayer.fireEvent('chatMessage', {chatMessageContent: chatMsg})
	                $this.pmBtnAppDatalayer.chatMessageEvent(PmBtnAppDatalayer.QUICK_CHAT_SECTION, chatMsg)	

				},
				(error) => {                                
					console.error(error);                
					submitBtn.disabled = false;
				}
			);
		});		
    }	

    addExtraFormData(formData){
    	const sourceOrigin = window.location.origin;
        formData.set("sessionId", this.pmchat.config.sessionId);
	  	formData.set("user_agent", BrowserHelper.getUserAgentName());
        formData.set("source_url", window.location.href.replace(sourceOrigin, "").substring(1));
        formData.set("source_origin", sourceOrigin);
        formData.set("site_token", this.pmchat.config.site_token);
        formData.set("leadOEM", this.pmchat.config.oem);
        return formData;
    }

    setLeadIdValue(){
		var $this = this;
		let selector = $this.getSelector("input[name=leadId]");
		DOMHelper.querySelectorAction(selector, (item) => {
			item.value = $this.generateLeadId();
		});
	}

	/**
	 * Lead id format required from PM api, to store it in mongodb
	 * @return string
	 */
	generateLeadId(){
        return new Date().getTime() + this.getRandomHexString(11);                                        
    }

    /**
     * created to complete lead id with 11 random chars
     * @param  int length [description]
     * @return string
     */
    getRandomHexString(length) {
        var randomChars = 'abcdef0123456789';
        var result = '';
        for ( var i = 0; i < length; i++ ) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

	showFormErrorMsg(msg){
        var selector = this.getSelector(".pmcb-msg-form-error");
        DOMHelper.setInnerHTML(selector, msg);

        setTimeout(() => {
            DOMHelper.setInnerHTML(selector, "");
        }, 4000);
    }

	getSelector(selector){
        return "." + this.wrapperClass + "#" + this.appId + " " + selector;		
	}
}
