import { RequestHelper } from "../Helpers/RequestHelper.js";

export class PmDealersite {
    constructor(config) {
        this.config = config;
    }

    getRootURL() {
        if (this.config.env == 'production') {
            return location.origin;
        }
        return "http://dealersite.loc";
    }

    getURL() {
        let route = "/index.php?pmGetDescriptions=all";
        return this.getRootURL() + route;
    }

    getTwilioUrl() {
        let route = "/pm/sendTwilio";
        return this.getRootURL() + route;
    }
    
    getCCPATCPADescriptions(successCallback, errorCallback) {
        RequestHelper.ajax("GET", this.getURL(), {}, successCallback, errorCallback);
    }

    // send twilio message
    sendTwilioMsg(data, successCallback, errorCallback) {
        RequestHelper.ajax("post", this.getTwilioUrl(), data, successCallback, errorCallback);
    }
}
