import { DOMHelper } from "../Helpers/DOMHelper.js";

export class PmDatalayer {

    constructor(pmCustomerApi) {
        this.pmCustomerApi = pmCustomerApi;
        this.sessionId = null;
    }

    postEvent(data){
        data.sessionData = {
            sessionId: this.sessionId,
            platform: navigator.platform,
            userAgent: navigator.userAgent
        }
        this.pmCustomerApi.saveCustomerTrackingActivity(data);
    }    

    setSessionId(sessionId) {
        this.sessionId = sessionId;
    }

    /**
     * @param  String section Section of the widget where the element is located
     * @param  String element custom name to describe the clicked element like step-btn, vehicle image, button, link
     * @param  String domElement event that fired the event
     * @return void
     */
    generalClickEvent(section, element, domElement){
        console.log('generalClickEvent');
        console.log(element);
        console.log(domElement);
        var text = domElement.textContent || "";
        var tagName = domElement.tagName;
        var eName = domElement.getAttribute('name') || "";
        let dataDataleyer = domElement.getAttribute('data-datalayer') || "";
        var data = {
            event: 'generalClick',
            section: section,
            data: {
                action: tagName + " click",
                tagName: tagName,
                element: element,
                elementText: text,
                elementName: eName,
                elementValue: domElement.value || null,
                dataDatalayer: dataDataleyer,
            }
        }
        console.log(data);
        this.postEvent(data);

        // if (element == "submit") {
        //     //HERE THE CUSTOMER IS COMPLETE, FORM IS DONE
        //     this.app.pmdrs.config.onCustomerComplete();
        //     //RESET SESSION ID 
        //     this.app.PmBtnAppDatalayer.resetSessionId();
        // }
    }


    /**
     * @param  String section Section of the widget where the element is located
     * @param  String element custom name to describe the clicked element like step-btn, vehicle image, button, link
     * @param  String domElement event that fired the event
     * @return void
     */
    ctaClickEvent(section, element, domElement){
        console.log('ctaClickEvent');
        console.log(element);
        var text = domElement.textContent || "";
        var tagName = domElement.tagName;
        // var eName = domElement.getAttribute('name') || "";
        var data = {
            event: 'ctaClick',
            section: section,
            data: {
                tagName: tagName,
                element: element,
                elementText: text,
                // elementName: eName,
            }
        }
        console.log(data);
        this.postEvent(data);
    }


    /**
     * @param  String section Section of the widget where the element is located
     * @param  String element custom name to describe the clicked element like step-btn, vehicle image, button, link
     * @param  String domElement event that fired the event
     * @return void
     */
    submitEvent(section, element, domElement, leadId, vehicle){
        console.log('submitEvent');
        console.log(element);
        var text = domElement.textContent || "";
        var tagName = domElement.tagName;
        var data = {
            event: 'formSubmission',
            section: section,
            data: {
                formTitle: "Speed DRS",
                leadId: leadId,
                displayFormat: 'on page',
                vehicle: vehicle,
                event: 'formSubmission',
                tagName: tagName,
                element: element,
                elementText: text
            }
        }
        console.log(data);
        this.postEvent(data);
    }

    /**
     * @param  String section Section of the widget where the element is located     
     * @param  String action to know if video was played or closed
     * @return void
     */
    videoInteractionEvent(section, action){
        console.log('videoInteractionEvent');
        // var text = domElement.textContent || "";
        // var eName = domElement.getAttribute('name') || "";
        var data = {
            event: 'videoInteraction',
            section: section,
            data: {
                action: action,
                // elementText: text,
                // elementName: eName,
            }
        }
        console.log(data);
        this.postEvent(data);
    }


    /**
     * @param  String section Section of the widget where the element is located
     * @param  String domElement input field that failed validation     
     * @param  String errorType to describe the failed validation like required, format, length     
     * @return void
     */
    validationFailedEvent(section, domElement, errorType){
        console.log('validationFailedEvent');
        console.log(domElement);
        var data = {
            event: 'validationFailed',
            data: {
                section: section,
                tagName: domElement.tagName,
                element: 'form field',                
                elementName: domElement.getAttribute('name'),
                elementValue: domElement.value,
                errorType: errorType
            }
        }
        console.log(data);

        this.postEvent(data);
    }

    chatImpressionEvent(section) {
        console.info("chatImpressionEvent");
         var data = {
            event: 'chatImpression',
            data: {
                section: section,                
            }
        }
        this.postEvent(data);
    }

    chatClickEvent(section) {
        console.info("chatClickEvent");
         var data = {
            event: 'chatClick',
            data: {
                section: section,                
            }
        }
        this.postEvent(data);
    }

    chatMessageEvent(section, chatMsgContent) {
        console.info("chatMessageEvent");
         var data = {
            event: 'chatMessage',
            data: {
                section: section,       
                chatMessageContent: chatMsgContent         
            }
        }
        this.postEvent(data);
    }

    chatLeadEvent(section, chatLeadId, formData) {
        console.info("chatLeadEvent");
         var data = {
            event: 'chatLead',
            data: {
                section: section, 
                chatLeadId: chatLeadId               
            }
        }
        this.postEvent(data);
    }

    chatClosedEvent(section){
        console.info("chatClosedEvent");
        var data = {
            event: 'chatClosed',
            data: {
                section: section, 
            }
        }
        this.postEvent(data);   
    }

}

// function generateSessionId() {
//     var timestamp = new Date().getTime();
//     var randomPart = Math.floor(Math.random() * 10000); // Add randomness for uniqueness
//     return `${timestamp}${randomPart}`;
// }

