import { RequestHelper } from "../Helpers/RequestHelper.js";

export class PmCustomerAPI {
    /**
     * 
     * @param Object config {production: bool, siteToken: ''}
     * @return {[type]}
     */
    constructor(pmchat) {
        this.pmchat = pmchat;
    }

    // getting URLs
    getRootURL() {        
        if (this.pmchat.isProduction()) {
            return "https://customers.dev.pixelmotiondemo.com/";
        }
        return "http://localhost:85/";
    }

    getURL() {
        return this.getRootURL() + "api";
    }

    saveCustomerTrackingActivity(data, successCallback, errorCallback) {
        var siteToken = this.pmchat.config.site_token;        
        RequestHelper.ajax("POST", this.getURL() + "/customerEvent/save/" + siteToken, data, successCallback, errorCallback);
    }

    //COMMENTED OUT UNTIL THEY'RE IN USE
    // saveCustomer(data, successCallback, errorCallback) {
    //     RequestHelper.ajax("POST", this.getURL() + "/customer/createUpdate/", data, successCallback, errorCallback);
    // }

    // createCustomerJourneyCTA(data, successCallback, errorCallback) {
    //     RequestHelper.ajax("POST", this.getURL() + "/customerJourney/createCustomerJourneyCTA/" + data.sessionId + "/" + data.siteToken, data, successCallback, errorCallback);
    // }

    // createCustomerJourney(data, successCallback, errorCallback) {
    //     RequestHelper.ajax("POST", this.getURL() + "/customerJourney/createCustomerJourney/" + data.sessionId + "/" + data.siteToken + "/" + data.customerId + "/" + data.leadId, data, successCallback, errorCallback);
    // }

    // updateCustomerJourney(data, successCallback, errorCallback) {
    //     RequestHelper.ajax("GET", this.getURL() + "/customerJourney/updateCustomerJourney/" + data.CJId + "/" + data.sessionId, successCallback, errorCallback);
    // }
}