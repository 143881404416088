import { DOMHelper } from "../Helpers/DOMHelper.js";

export class ShiftBmwModule {

    constructor(shiftDatalayer) {        
        this.shiftDatalayer = shiftDatalayer;
        this.initiatedForms = {};// to remember and not double fire it
        this.enabled = false;
        this.chatProviderName ='pixelmotion';
    }

    /**
     * 
     * @returns boolean true if it's enabled 
     */
    init() {        
        this.enabled = 'sdb' in window;
        return this.enabled;
    }

    formInitiationEvent ({formType}) {
        return;//disabled SD appendix 2, not for chat
        console.info("PmDrsShift: formInitiation");        
        sdbDataLayer = {
            formType: formType,
            displayType: 'modal',
            events: 'formInitiation',
            formVehicle: this.shiftDatalayer.getVehicleDetails()
        };                
        sdb('send');
        return sdbDataLayer;
    }

    formFieldInteractionEvent ({formType, formFieldName}) {
        return;//disabled SD appendix 2, not for chat
        console.info("PmDrsShift: formFieldInteraction");
        sdbDataLayer = {
            formType: formType,
            displayType: 'modal',
            events: 'formFieldInteraction',
            formFieldName: formFieldName,
            formVehicle: this.shiftDatalayer.getVehicleDetails()
        };

        sdb('send');
        return sdbDataLayer;
    }

    formShownEvent({formType}) {
        return;//disabled SD appendix 2, not for chat
        console.info("PmDrsShift: formShown");

        var sdbDataLayerFormShown = {
            formType: formType,
            events: 'formShown',
            formVehicle: this.shiftDatalayer.getVehicleDetails()
        };

        sdb('dataLayer', sdbDataLayerFormShown);//trying to get formShown event displayed in SD Analytics tab
        sdb('send');
        return sdbDataLayerFormShown;
    }

    formSubmissionEvent({leadId, formType, prefContact}) {
        return;//disabled SD appendix 2, not for chat
        console.info("PmDrsShift: formSubmission");

        sdbDataLayer = {
            leadId: leadId,
            formType: formType,
            leadType: 'lead', //Indicates if the form is a lead, a handraiser (notintended to be sent to the dealer), or both
            // prefContact: prefContact,
            events: 'formSubmission',
            formVehicle: this.shiftDatalayer.getVehicleDetails()
        };

        sdb('send');
        return sdbDataLayer;
    }

    chatImpressionEvent({}) {
        console.info("PmDrsShift: chatImpression");

        sdbDataLayer = { 
            chatProfitCenter: 'sales',
            chatType: 'chat',
            chatSessionId: this.shiftDatalayer.getSessionId(),
            chatProvider: this.chatProviderName,
            events: 'chatImpression'
        }

        sdb('send');
        return sdbDataLayer;
    }

    chatClickEvent({}) {
        console.info("PmDrsShift: chatClick");

        sdbDataLayer = { 
            chatProfitCenter: 'sales',
            chatType: 'chat',
            chatSessionId: this.shiftDatalayer.getSessionId(),
            chatProvider: this.chatProviderName,
            events: 'chatClick'
        }

        sdb('send');
        return sdbDataLayer;
    }

    chatMessageEvent({chatMsgContent}) {
        console.info("PmDrsShift: chatMessage");

        sdbDataLayer = { 
            chatProfitCenter: 'sales',
            chatType: 'chat',
            chatSessionId: this.shiftDatalayer.getSessionId(),
            chatProvider: this.chatProviderName,
            chatMessageType: 'user',
            chatMessageContent: chatMsgContent,
            events: 'chatUserMessage'
        }

        sdb('send');
        return sdbDataLayer;
    }

    chatLeadEvent({chatLeadId}) {
        console.info("PmDrsShift: chatLead");

        sdbDataLayer = { 
            chatProfitCenter: this.shiftDatalayer.app.state.selectedDepartment,
            chatType: 'chat',
            chatSessionId: this.shiftDatalayer.getSessionId(),
            chatProvider: this.chatProviderName,
            chatLeadId: chatLeadId,            
            events: 'chatLead'
        }

        sdb('send');
        return sdbDataLayer;
    }

    chatDrHandoffEvent({}) {//probably won't be used
        console.info("PmDrsShift: chatDrHandoff");

        sdbDataLayer = { 
            chatProfitCenter: this.shiftDatalayer.app.state.selectedDepartment,
            chatType: 'DR',
            chatSessionId: this.shiftDatalayer.getSessionId(),
            chatProvider: this.chatProviderName,
            events: 'chatDrHandoff'
        }

        sdb('send');
        return sdbDataLayer;
    }

    chatClosedEvent({}) {
        console.info("PmDrsShift: chatClosed");

        sdbDataLayer = { 
            chatProfitCenter: this.shiftDatalayer.app.state.selectedDepartment,
            chatType: 'chat',
            chatSessionId: this.shiftDatalayer.getSessionId(),
            chatProvider: this.chatProviderName,
            events: 'chatClosed'
        }

        sdb('send');
        return sdbDataLayer;
    }
}